<template>
    <v-container style="min-height: 100vh;">
        <client-pricing-component/>
        <v-row justify="center" align="center">
            <v-col class="text-center">
                <v-btn
                        v-if="cartItems"
                        rounded
                        color="primary"
                        class="white--text px-5 mb-12"
                        @click="goToCart"
                >
                    <v-icon class="mx-2">mdi-cart-arrow-right</v-icon>
                    {{$t('$vuetify.CompletePurchase')}}
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import ClientPricingComponent from "../../components/products/ClientPricingComponent";
    export default {
        components: {
            ClientPricingComponent
        },
        computed: {
            cartItems() {
                return this.$store.getters.getCartItems.length !== 0;
            },
            productList() {
              return this.$store.getters.getProductList;
            },
            loading() {
                return this.productList.length == 0 ? true : false;
            }
        },
        created() {
            // console.log('loading', this.productList.length);
        },
        methods: {
            goToCart() {
                this.$router.push({name: 'clientCart'});
            }
        }
    }
</script>

<style scoped>

</style>